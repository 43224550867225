<template>
  <div>
    <DeviceDetails />
  </div>
</template>

<script>

import DeviceDetails from '@/components/device/DeviceDetails';

export default {
  name: 'DetailsDevice',

  components: {
    DeviceDetails,
  },
};

</script>
